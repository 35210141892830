
import { defineComponent, ref, computed } from "vue";
import { multipleSelectionLabel } from "@/modules/palveluhaku/utils/input-utils";
import { useStore } from "vuex";

export default defineComponent({
    props: {
        multiple: Boolean,
        label: String,
        onlyPalveluntuottajat: Boolean,
        onlyValvojat: Boolean,
        hideIcon: Boolean,
        allowInput: Boolean,
    },
    setup(props) {
        const store = useStore();
        const organisaatio = ref(null);

        const displayValue = computed(() => {
            return props.multiple
                ? multipleSelectionLabel(organisaatio.value)
                : (organisaatio.value as any)?.name;
        });

        const organisations = computed(() => {
            const { organisations } = store.state;
            let filterCondition: string | null = null;
            if (props.onlyPalveluntuottajat) {
                filterCondition = "palveluntuottaja";
            }
            if (props.onlyValvojat) {
                filterCondition = "valvoja";
            }
            return filterCondition
                ? organisations.filter((o: any) => o[filterCondition as string])
                : organisations;
        });

        const filteredOrganisations = ref(organisations.value);

        function filterFn(val: string, update: any) {
            update(() => {
                if (val.length >= 3 || !val.length) {
                    const needle = val.toLowerCase();
                    filteredOrganisations.value = organisations.value.filter(
                        (item: any) => item.name.toLowerCase().indexOf(needle) > -1
                    );
                }
            });
        }

        return {
            displayValue,
            organisaatio,
            filteredOrganisations,
            multipleSelectionLabel,
            filterFn,
        };
    },
});
