
import { defineComponent, computed } from "vue";
import DateInput from "@/components/input/DateInput.vue";
import OrganisaatioInput from "@/components/input/OrganisaatioInput.vue";
import PalveluyksikotInput from "@/components/PalveluyksikotInput.vue";
import PalvelumuotoInput from "@/components/input/PalvelumuotoInput.vue";
import { priceRegex } from "@/utils/regex";
import { localStringToDate } from "@/utils/date";

export default defineComponent({
    components: { DateInput, OrganisaatioInput, PalveluyksikotInput, PalvelumuotoInput },
    props: {
        modelValue: Object,
        showError: Boolean,
        isNewPuitesopimus: Boolean,
        puitesopimusLoading: Boolean,
        savingPuitesopimus: Boolean,
        hasUniquePrices: Boolean,
        filteredYksikot: Function,
    },
    emits: [
        "update:modelValue",
        "addPriceRow",
        "backToPuitesopimukset",
        "deleteRow",
        "copyRow",
        "handleSave",
    ],
    setup(props, { emit }) {
        const form: any = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });
        const validFrom = computed(() => localStringToDate(form.value.valid_from));

        const validUntil = computed(() => localStringToDate(form.value.valid_until));

        return { form, priceRegex, validFrom, validUntil };
    },
});
