
import { defineComponent, Ref, ref, computed } from "vue";
import { formattedYear, formattedDate, mask, locale } from "@/utils/date";

export default defineComponent({
    props: {
        label: String,
        modelValue: String,
        showError: Boolean,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const qDateProxy: Ref<any> = ref(null);

        let date = computed({
            get: () => props.modelValue,
            set: (value) => {
                qDateProxy.value?.hide();
                emit("update:modelValue", value);
            },
        });

        const dateText = computed(() => {
            return date.value ? formattedDate(date.value) : "";
        });

        const title = computed(() => {
            return date.value ? formattedDate(date.value) : "Valitse päivämäärä";
        });

        const subTitle = computed(() => {
            const dateObj = date.value ? new Date(date.value) : new Date();
            return formattedYear(dateObj);
        });

        return { date, dateText, mask, locale, title, subTitle, qDateProxy };
    },
});
