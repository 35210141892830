import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    modelValue: _ctx.organisaatio,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.organisaatio = $event)),
    multiple: _ctx.multiple,
    outlined: "",
    "hide-dropdown-icon": "",
    options: _ctx.filteredOrganisations,
    dense: "",
    label: _ctx.label,
    "options-dense": "",
    "option-value": "id",
    "option-label": "name",
    "display-value": _ctx.displayValue,
    onFilter: _ctx.filterFn,
    "input-debounce": "0",
    "fill-input": _ctx.allowInput,
    "use-input": _ctx.allowInput,
    "hide-selected": _ctx.allowInput
  }, _createSlots({ _: 2 }, [
    (!_ctx.hideIcon)
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "fas fa-building" })
          ])
        }
      : undefined
  ]), 1032, ["modelValue", "multiple", "options", "label", "display-value", "onFilter", "fill-input", "use-input", "hide-selected"]))
}