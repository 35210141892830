import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PuitesopimusForm = _resolveComponent("PuitesopimusForm")!

  return (_openBlock(), _createBlock(_component_PuitesopimusForm, _mergeProps(_ctx.$attrs, {
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form = $event)),
    "is-new-puitesopimus": _ctx.isNewPuitesopimus,
    "show-error": _ctx.showError,
    "puitesopimus-loading": _ctx.puitesopimusLoading,
    "has-unique-prices": _ctx.hasUniquePrices,
    onHandleSave: _ctx.handleSave,
    onDeleteRow: _ctx.deleteRow,
    onCopyRow: _ctx.copyRow,
    onBackToPuitesopimukset: _ctx.backToPuitesopimukset,
    "filtered-yksikot": _ctx.filteredYksikot
  }), null, 16, ["modelValue", "is-new-puitesopimus", "show-error", "puitesopimus-loading", "has-unique-prices", "onHandleSave", "onDeleteRow", "onCopyRow", "onBackToPuitesopimukset", "filtered-yksikot"]))
}