import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    onFilter: _ctx.filterFn,
    outlined: "",
    "hide-dropdown-icon": "",
    loading: !_ctx.filteredPalveluyksikot,
    options: _ctx.filteredPalveluyksikot,
    label: "Toimintayksikkö",
    "menu-shrink": "",
    "option-value": "id",
    "option-label": "name",
    "fill-input": "",
    "use-input": "",
    "input-debounce": "0",
    "hide-selected": "",
    dense: "",
    "no-error-icon": "",
    "hide-bottom-space": "",
    "options-dense": "",
    modelValue: _ctx.palveluyksikko,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.palveluyksikko = $event)),
    clearable: "",
    "clear-icon": "fas fa-times"
  }, null, 8, ["onFilter", "loading", "options", "modelValue"]))
}