
import { defineComponent, reactive, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Puitesopimus } from "../@types/Puitesopimus";
import PuitesopimusForm from "./PuitesopimusForm.vue";
import { PalveluntuottajaHinta } from "../@types/PalveluntuottajaHinta";
import { PALVELUMUODOT } from "@/modules/palveluhaku/constants/Palveluhaku";
import { fixPrice } from "@/utils/price";
import { validatePrices } from "@/utils/price";
import { localStringToDate } from "@/utils/date";

export default defineComponent({
    components: { PuitesopimusForm },
    setup() {
        const initialForm: Puitesopimus = {
            name: "",
            valid_from: "",
            valid_until: "",
            prices: [
                {
                    palvelutuottaja: null,
                    palveluyksikko: null,
                    palvelumuoto: null,
                    price_min: null,
                    price_max: null,
                },
            ],
        };
        let form: any = reactive({ ...initialForm });
        const showError = ref(false);
        const store = useStore();

        const route = useRoute();
        const router = useRouter();
        const puitesopimusIdParam = computed(() => route.params.id);
        const savingPuitesopimus = computed(() => store.state.puitesopimukset.saving);
        const savingPuitesopimusFailed = computed(() => store.state.puitesopimukset.savingFailed);
        const puitesopimusLoading = computed(() => store.state.puitesopimukset.puitesopimusLoading);

        const puitesopimus = computed(() => store.state.puitesopimukset.puitesopimus);

        const isNewPuitesopimus = computed(() => puitesopimusIdParam.value === "new");

        const palveluyksikot = computed(() => store.state.palveluyksikot);

        const filteredYksikot = (organisationId: number) =>
            palveluyksikot.value.filter((py: any) => py.palvelutuottaja === organisationId);
        if (!isNewPuitesopimus.value) {
            store.dispatch("puitesopimukset/fetchPuitesopimusById", puitesopimusIdParam.value);
        }

        const backToPuitesopimukset = () => {
            router.push("/puitesopimukset");

            // handleSave(true);
        };

        const hasUniquePrices = computed(
            () =>
                [
                    ...new Set(
                        form.prices.map(
                            (priceRow: PalveluntuottajaHinta) =>
                                `${priceRow.palvelumuoto?.id}${priceRow.palveluyksikko?.id}`
                        )
                    ),
                ].length === form.prices.length
        );

        const hasFilledFields = computed(() => {
            const { name, valid_from, valid_until, prices } = form;
            const pricesFilled = validatePrices(prices);
            const valid_fromDate = localStringToDate(valid_from);
            const valid_untilDate = localStringToDate(valid_until);

            return (
                name &&
                valid_fromDate &&
                valid_untilDate &&
                valid_fromDate! < valid_untilDate! &&
                pricesFilled &&
                hasUniquePrices.value
            );
        });

        watch(puitesopimus, () => {
            const formattedPrices = puitesopimus.value.prices.map((p: any) => ({
                ...p,
                palvelumuoto: PALVELUMUODOT.find((t) => t.id === p.palvelumuoto),
            }));
            Object.assign(form, { ...puitesopimus.value, prices: formattedPrices });
        });

        // commented out autosave for now
        // watch(form, (form) => {
        //     if (
        //         (!puitesopimus.value || form.prices.length !== puitesopimus.value.prices.length) &&
        //         !savingPuitesopimus.value
        //     ) {
        //         // autosave form when prices row have been added
        //         handleSave();
        //     }
        // });

        const handleSave = (showNotification?: boolean) => {
            if (showNotification) {
                showError.value = true;
            }
            if (hasFilledFields.value) {
                savePuitesopimus(showNotification);
            }
        };

        const savePuitesopimus = async (showNotification?: boolean) => {
            const { uuid, name, valid_from, valid_until, prices } = form;
            const pricesArr = prices.map((pt: PalveluntuottajaHinta) => {
                const { price_max, price_min, palvelumuoto, palveluyksikko } = pt;
                return {
                    price_min: fixPrice(price_min),
                    price_max: fixPrice(price_max),
                    palveluyksikko_id: palveluyksikko?.id,
                    palvelumuoto: palvelumuoto?.id,
                };
            });
            const formattedForm: Puitesopimus = {
                uuid,
                name,
                valid_from: localStringToDate(valid_from)!.toISOString(),
                valid_until: localStringToDate(valid_until)!.toISOString(),
                prices: pricesArr,
            };
            await store.dispatch("puitesopimukset/savePuitesopimus", {
                puitesopimus: formattedForm,
                showNotification,
            });

            // if (showNotification && !savingPuitesopimusFailed.value) {
            //     router.push("/puitesopimukset");
            // }
        };

        const copyRow = () => {
            const { prices } = form;
            const lastRow = prices[prices.length - 1];
            prices.push({ ...lastRow, palvelumuoto: null, price_max: null, price_min: null });
        };

        const deleteRow = (index: number) => {
            const pricesArr = [...form.prices];
            pricesArr.splice(index, 1);
            form.prices = pricesArr;
        };

        return {
            handleSave,
            copyRow,
            form,
            isNewPuitesopimus,
            backToPuitesopimukset,
            deleteRow,
            showError,
            filteredYksikot,
            puitesopimusLoading,
            savingPuitesopimus,
            hasUniquePrices,
        };
    },
});
