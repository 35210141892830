
import { defineComponent, PropType, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { Option } from "@/@types/Option";

export default defineComponent({
    props: {
        modelValue: Object as PropType<Option>,
        filteredYksikot: Array,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const palveluyksikko = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        const store = useStore();

        const palveluyksikot = computed(() => props.filteredYksikot || store.state.palveluyksikot);
        const filteredPalveluyksikot = ref(palveluyksikot.value);

        watch(palveluyksikot, () => {
            if (!palveluyksikot.value.some((py: any) => py.id === palveluyksikko.value?.id)) {
                palveluyksikko.value = undefined;
            }
        });

        function filterFn(val: string, update: any) {
            update(() => {
                if (val.length >= 3 || !val.length) {
                    const needle = val.toLowerCase();
                    filteredPalveluyksikot.value = palveluyksikot.value.filter(
                        (item: any) => item.name.toLowerCase().indexOf(needle) > -1
                    );
                }
            });
        }

        return { filteredPalveluyksikot, filterFn, palveluyksikko };
    },
});
